import * as React from "react";
import { Box, Button, FormField, Input, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { PasswordResetState } from "./PasswordResetPanel";
import { Auth } from "@awsscm/awsscm-auth-manager/auth/AuthManager";
import { NotificationBox, resetMessageStatus } from "../notification/NotificationBox";
import { AuthState, NotificationType, UserState } from "../user/UserState";
import "../login/LoginPanel.scss";
import "../AWS_Infrastructure.scss";
import { Header } from "../login/Header";

export interface UsernameValidationPanelProps {
  passwordRestState: PasswordResetState
  setPasswordRestState: (passwordRestState: PasswordResetState) => void,
  setUserState: (userState: UserState) => void,
  userState: UserState
}

/**
 * Validate user's email address in password reset flow.
 *   - If input email address exists in Argo identity pool,
 *   authentication code will be sent to the email address to complete 2nd factor authentication.
 *   - Otherwise, throw error message in notification box.
 *
 * @param props component props.
 */
export const UsernameValidationPanel = (props: UsernameValidationPanelProps) => {
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const submitForgetPasswordRequest = (username: string) => {
    setLoading(true);
    Auth.initiatePasswordReset(username).then(() => {
      props.setUserState({
        authenticated: props.userState.authenticated, authState: props.userState.authState, user: null, message: null
      });
      props.setPasswordRestState({emailVerified: true, username: username});
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      props.setUserState({
        authenticated: false,
        authState: AuthState.PASSWORD_RESET,
        user: props.userState.user,
        message: {
          message: "If entered email is registered, you will receive an email with the authentication code.",
          type: NotificationType.INFO
        }
      });
    })
  };

  return (
    <Modal
      size={"small"}
      // close username validation panel will navigate back to main login page
      onDismiss={(event) => {
        if (event.detail.reason === 'closeButton') {
          props.setUserState({
            authenticated: false,
            authState: AuthState.SIGN_IN,
            user: props.userState.user,
            message: null
          })
        }
      }}
      visible={!props.passwordRestState.emailVerified}
      closeAriaLabel="Close modal"
      // headers
      header={<Header/>}
    >
      <SpaceBetween size={"m"}>
        <b> Enter the registered email address to receive an authentication code for password reset. </b>

        <FormField label="Registered email address">
          <Input onChange={({detail}) => {
            resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
            setEmailAddress(detail.value.replaceAll(" ", ""));
          }} value={emailAddress} onKeyDown={(event) => {
            if (event.detail.keyCode === 13) {
              submitForgetPasswordRequest(emailAddress);
            }
          }} placeholder="example@amazon.com"/>
        </FormField>

        <NotificationBox userState={props.userState} setUserState={props.setUserState}/>

        <Box textAlign={"center"}>
          <SpaceBetween size={"m"} direction={"vertical"}>
            <Button variant="primary"
                    onClick={() => submitForgetPasswordRequest(emailAddress)}
                    disabled={emailAddress === ""}
                    loading={loading}>
              Send
            </Button>
          </SpaceBetween>
        </Box>

      </SpaceBetween>
    </Modal>
  );
}